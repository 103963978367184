<template>
    <a-drawer
            title=""
            placement="right"
            :closable="false"
            :visible="visible"
            :width="500"
            :bodyStyle="{'padding':0}"
            @close="onBBSPersonalAreaModalClose()"
    >
        <div class="huiwen-container huiwen-left">
            <div class="huiwen-container huiwen-left huiwen-border-box huiwen-bg-blue" style="padding: 15px 15px 30px 15px;">
                <div class="huiwen-container huiwen-left" style="display: flex;justify-content: center;align-items: center;">
                    <div class="huiwen-left" >
                        <img :src="staff_info.avatar" class="huiwen-left staff-avatar"></img>
                    </div>
                </div>
                <div class="huiwen-container huiwen-left huiwen-margin-top-10 huiwen-text-center" style="line-height: 15px;">
                    <div class="huiwen-text-common huiwen-text-white ">{{staff_info.staff_name}}
                        <span v-if="staffId != staff_id" :class="staff_info.is_followed == 0 ? 'guanzhu-btn huiwen-pointer' : 'cancel-guanzhu-btn huiwen-pointer'" @click="doFollow(staff_info.staff_id)">
                            {{staff_info.is_followed == 0 ? '+关注' : '已关注'}}
                        </span>
                    </div>
                </div>
                <div class="huiwen-container huiwen-left huiwen-margin-top-20">
                    <div class="huiwen-container huiwen-left huiwen-layout-flex-left-right">
                        <div style="flex: 1;">
                            <div class="huiwen-container huiwen-left huiwen-text-center">
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white huiwen-text-common">{{staff_info.cnt_post}}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white" style="font-size: 12px;">发帖数</span>
                                </div>
                            </div>
                        </div>
                        <div style="flex: 1;">
                            <div class="huiwen-container huiwen-left huiwen-text-center">
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white huiwen-text-common">{{staff_info.cnt_comment}}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white" style="font-size: 12px;">回帖数</span>
                                </div>
                            </div>
                        </div>
                        <div style="flex: 1;">
                            <div class="huiwen-container huiwen-left huiwen-text-center">
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white huiwen-text-common">{{staff_info.cnt_is_praised}}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white" style="font-size: 12px;">被点赞</span>
                                </div>
                            </div>
                        </div>
                        <div style="flex: 1;">
                            <div class="huiwen-container huiwen-left huiwen-text-center">
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white huiwen-text-common">{{staff_info.cnt_is_favored }}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white" style="font-size: 12px;">被收藏</span>
                                </div>
                            </div>
                        </div>
                        <div style="flex: 1;">
                            <div class="huiwen-container huiwen-left huiwen-text-center">
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white huiwen-text-common">{{staff_info.cnt_is_followed}}</span>
                                </div>
                                <div class="huiwen-container huiwen-left" style="height: 15px;line-height: 15px;">
                                    <span class="huiwen-text-white" style="font-size: 12px;">被关注</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="huiwen-container huiwen-left tab-bar">
                <div class="huiwen-left huiwen-layout-flex-center" style="width: 50%;height: 40px;">
                    <div :class="mode == 'post' ? 'tab-btn-active' : 'tab-btn'" @click="changeMode('post')">
                        发帖
                    </div>
                </div>
                <div class="huiwen-left huiwen-layout-flex-center" style="width: 50%;height: 40px;">
                    <div :class="mode == 'comment' ? 'tab-btn-active' : 'tab-btn'" @click="changeMode('comment')">
                        回帖
                    </div>
                </div>
            </div>
            <div class="huiwen-container huiwen-left">
                <template v-if="mode == 'post' && posts.length > 0">
                    <div v-for="(item, index) in posts"  v-if="item.is_hot != '9'" class="content-box huiwen-pointer" @click="toComminicate(item.post_id)">

                        <div class="title">
                            <span class="center">
                                <span v-if="item.post_type == '1'"class="pre-tag" :style="{'background-color' : item.cnt_adopted > 0 ? '#8e8e93' : ''}">问</span>
                                <span v-if="item.is_hot == '1'" class="iconfont icon-remen huiwen-text-red" style="font-size: 20px;font-weight: normal;"></span>
                                {{item.title}}

                                <span v-if="item.post_type == '1' && item.award > 0" class="suf-tag">
                                    <span class="iconfont icon-coin " style="color: #FCBA1B;font-size: 13px;"></span>
                                    <span class="huiwen-text-red" style="font-weight: 500;margin-left: 2px;font-size: 14px;">{{item.award}}</span>
                                </span>
                            </span>

                        </div>

                        <div v-if="item.img1 || item.img2 || item.img3 || item.img4" class="img-box">
                            <!-- 只有一张图 -->
                            <template v-if="(item.img1 && !item.img2 && !item.img3 && !item.img4) || (item.img1 && item.img2 && !item.img3 && !item.img4)">
                                <div class="huiwen-container huiwen-layout-flex-left-right" style="flex-direction: row;">
                                    <div style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img :src="item.img1" class="huiwen-container" :style="{'height': ((500 -30 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                    <div style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img v-if="item.img2" :src="item.img2" class="huiwen-container" :style="{'height': ((500 -48 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                    <div style="flex: 1;padding: 5px;" class="huiwen-border-box"></div>
                                    <div style="flex: 1;padding: 5px;" class="huiwen-border-box"></div>
                                </div>
                            </template>
                            <!-- 有多张图 -->
                            <template v-else>
                                <div class="huiwen-container huiwen-layout-flex-left-right">
                                    <div v-if="item.img1" style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img :src="item.img1" class="huiwen-container" :style="{'height': ((500 -30 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                    <div v-if="item.img2" style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img v-if="item.img2" :src="item.img2" class="huiwen-container" :style="{'height': ((500 -48 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                    <div v-if="item.img3" style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img v-if="item.img3" :src="item.img3" class="huiwen-container" :style="{'height': ((500 -48 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                    <div v-if="item.img4" style="flex: 1;padding: 5px;" class="huiwen-border-box">
                                        <img v-if="item.img4" :src="item.img4" class="huiwen-container" :style="{'height': ((500 -48 -40)*0.618/4) + 'px','object-fit': 'cover'}"></img>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div v-else class="desc huiwen-over-two">
                            {{item.desc}}
                        </div>
                        <div class="info-box">
                            <div class="left">
                                <div class="huiwen-left avatar-box huiwen-layout-flex-center">
                                    <img :src="item.avatar"></img>
                                </div>
                                <div class="huiwen-left sender-name">
                                    {{item.post_staff_name}}
                                </div>
                                <div class="huiwen-left sender-time">
                                    {{item.post_time}}
                                </div>
                            </div>
                            <div class="center">
                                <div class="huiwen-container huiwen-left" style="height: 16px;"></div>
                                <div v-if="item.forumtype" class="huiwen-container huiwen-left huiwen-text-center" style="height: 16px;line-height: 16px;color: #8799a3;font-size: 12px;">
                                    {{item.forumtype}}
                                </div>
                            </div>
                            <div class="right">
                                <div class="huiwen-container huiwen-left" style="height: 16px;"></div>
                                <div class="huiwen-container huiwen-left" style="height: 16px;">
                                    <div class="huiwen-left huiwen-layout-flex-center" style="height: 16px;width: 50%;">
                                        <span class="iconfont icon-yanjing huiwen-text-gary-light"></span>
                                        <span class="wcs-margin-left-5 huiwen-text-gary-light" style="font-size: 12px;">{{item.cnt_div}}</span>
                                    </div>
                                    <div class="huiwen-left huiwen-layout-flex-center" style="height: 16px;width: 50%;">
                                        <span class="iconfont icon-31pinglun huiwen-text-gary-light"></span>
                                        <span class="wcs-margin-left-5 huiwen-text-gary-light" style="font-size: 12px;">{{item.cnt_comment}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-text-center huiwen-margin-top-10 huiwen-margin-bottom-10" style="height: 40px;">
                        <cmy-page :page_now="posts_pagination.page" :bo_end="posts_pagination.end" @next="queryPosts(posts_pagination.page + 1)" @previous="queryPosts(posts_pagination.page - 1)"></cmy-page>
                    </div>
                </template>
                <template v-else-if="mode == 'comment' && comments.length > 0">
                    <div v-for="(item, index) in comments" class="comment-box huiwen-pointer" @click="toComminicate(item.ref_post_id)">
                        <div class="huiwen-container huiwen-left">
                            <div class="huiwen-left" style="width: 70%;">
                                <div class="avatar-box huiwen-layout-flex-center">
                                    <img :src="staff_info.avatar"></img>
                                </div>
                                <div class="sender-name">
                                    {{staff_info.staff_name}}
                                </div>
                                <div class="sender-time">
                                    {{item.comment_time}}
                                </div>
                            </div>
                            <div  class="huiwen-left huiwen-layout-flex-tb-center" style="height: 32px;width: 30%;flex-direction: row-reverse;">
                                <div style="height: 32px;margin-left: 10px;" class="huiwen-layout-flex-center" @click.stop="doPraise(index)">
                                    <span :class="item.is_praised == 1 ?'iconfont icon-dianzan huiwen-text-orange huiwen-pointer' : 'iconfont icon-dianzan huiwen-text-gary-light huiwen-pointer'" style="font-size: 14px;"></span>
                                    <span :class="item.is_praised == 1 ? 'huiwen-text-orange huiwen-pointer' : 'huiwen-text-gary-light huiwen-pointer'" style="font-size: 12px;margin-left: 3px;">{{item.cnt_praise}}</span>
                                </div>
                                <div v-if="item.is_adopted == 1" style="height: 32px;" class="huiwen-layout-flex-center">
                                    <span class="adopted-tag" >被采纳</span>
                                </div>
                            </div>
                        </div>
                        <div class="huiwen-container huiwen-left comment-content huiwen-text-bold">
                            {{item.content}}
                        </div>
                        <div v-if="item.ref_comment_id" class="huiwen-container huiwen-left comment-ref huiwen-over-one">
                            {{item.ref_comment_staff_name + '：' + item.ref_comment_content}}
                            <div v-if="item.ref_post_id" class="huiwen-container huiwen-left comment-ref-2 huiwen-over-one" style="margin-top:10px;">
                                {{'原帖 | ' + item.ref_post_title}}
                            </div>
                        </div>
                        <div v-else-if="item.ref_post_id" class="huiwen-container huiwen-left comment-ref huiwen-over-one">
                            {{'原帖 | ' + item.ref_post_title}}
                        </div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-text-center huiwen-margin-top-10 huiwen-margin-bottom-10" style="height: 40px;">
                        <cmy-page :page_now="comments_pagination.page" :bo_end="comments_pagination.end" @next="queryComments(comments_pagination.page + 1)" @previous="queryComments(comments_pagination.page - 1)"></cmy-page>
                    </div>
                </template>
                <template v-else>
                    <a-empty  style="padding: 50px 0"/>
                </template>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { doQuery, doAction } from "@/api/core"
    import CmyPage from '@/components/cmy-page/cmy-page.vue'
    export default {
        name: "bbs-personal-area",
        components: {
            CmyPage
        },
        data () {
            return {
                visible: false,

                staff_info: {},
                posts_pagination: {
                    page: 1,
                    limit: 10,
                    end: false,
                },
                posts: [],

                comments_pagination: {
                    page: 1,
                    limit: 10,
                    end: false,
                },
                comments: [],
            }
        },
        props: {
            staff_id: String,
            // post 发帖 comment 回帖
            mode: {
                type: String,
                default: 'post'
            },
        },
        watch: {
            'staff_id': function (newVal,oldVal) {
                if(newVal) {
                    this.getStaffInfo(() => {
                        this.visible = true
                    })
                    this.queryData()
                }else {
                    this.visible = false
                }
            },
            'mode': function(newVal,oldVal) {
                if(newVal) {
                    this.queryData()
                }
            }
        },
        computed: {
            ...mapGetters([
                'staffId'
            ])
        },
        methods: {
            toComminicate(post_id) {
                const that = this
                that.$emit('close')
                if(that.$route.name == 'StudentPostDetail') {
                    that.$router.replace('/student/postDetail?post_id=' + post_id)
                }else {
                    that.$router.push('/student/postDetail?post_id=' + post_id)
                }
            },
            changeMode(mode) {
                const that = this
                that.$emit('changMode',mode)
            },
            doFollow(staff_id) {
                const that = this
                doAction({
                    action_code: 'LNFR40',
                    param_str2: staff_id
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                       that.$message.success(that.staff_info.is_followed == 1 ? '已取消关注' : '关注成功')
                        that.staff_info.is_followed = that.staff_info.is_followed == 1 ? 0 : 1
                        that.$emit('resetFollowCallback',that.staff_info.is_followed)
                    }else {
                        that.$message.error(res.errmsg)
                    }
                })
            },
            doPraise(comment_index = null) {
                const that = this
                doAction({
                    action_code: 'LNFR30',
                    param_str5: JSON.stringify({
                        post_id: that.comments[comment_index].ref_post_id,
                        comment_id: that.comments[comment_index].comment_id,
                        praise_staff_id: that.staffId,
                    })
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                        var bo_praise = that.comments[comment_index].is_praised == 0
                        var cnt_praise = that.comments[comment_index].cnt_praise
                        that.$message.success(!bo_praise ? '已取消点赞' : '点赞成功')
                        that.$set(that.comments[comment_index], 'is_praised', bo_praise ? 1 : 0)
                        that.$set(that.comments[comment_index], 'cnt_praise', bo_praise ? cnt_praise + 1 : cnt_praise - 1)
                    }else {
                        that.$message.error(res.errmsg)
                    }
                })
            },
            onBBSPersonalAreaModalClose() {
                const that = this
                that.$emit('close')
            },
            getStaffInfo(func) {
                const that = this
                doQuery({
                    query_code: 'LNFQ50',
                    param_str1: that.staff_id,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.staff_info = res.data[0]
                        if(func) {
                            func()
                        }
                    }
                })
            },
            queryData(page = 1) {
                const that = this
                if(that.mode == 'post') {
                    that.queryPosts(page)
                }
                if(that.mode == 'comment') {
                    that.queryComments(page)
                }
            },
            queryComments(page = 1) {
                const that = this
                doQuery({
                    query_code: 'LNFQ31',
                    param_str1: that.staff_id,
                    page: page,
                    limit: that.comments_pagination.limit
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.comments = res.data
                    }
                    that.comments_pagination.page = page
                    that.comments_pagination.end = res.data.length < that.comments_pagination.limit
                })
            },
            queryPosts(page = 1) {
                const that = this
                doQuery({
                    query_code: 'LNFQ10',
                    param_str3: '0',
                    param_str4: that.staff_id,
                    page: page,
                    limit: that.posts_pagination.limit
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.posts = res.data
                    }
                    that.posts_pagination.page = page
                    that.posts_pagination.end = res.data.length < that.posts_pagination.limit
                })
            }
        }
    }
</script>

<style scoped>
    @import "../../views/student/bbs/bbs.css";

    .adopted-tag {
        font-size: 12px;
        color: #ffffff;
        background-color: #04BE02;
        padding: 3px 10px;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .staff-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }

    .guanzhu-btn {
        height: 30px;
        padding: 0 10px;
        font-size: 13px;
        color: #ffffff;
        background-color: #e54d42;
        text-align: center;
        line-height: 30px;
        border-radius: 30px;
        margin-left: 10px;
    }

    .cancel-guanzhu-btn {
        height: 30px;
        padding: 0 10px;
        font-size: 13px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
        border-radius: 30px;
        border: 1px solid #ffffff;
        margin-left: 10px;
    }

    .tab-bar {
        height: 40px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #ffffff;
        margin-top: -15px;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .tab-bar-fixed {
        height: 40px;
        background-color: #ffffff;
        padding: 0 15px;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        border-bottom: 1px solid #f6f6f6;
        z-index: 9;
    }

    .tab-btn {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #494949;
        font-size: 15px;
        cursor: pointer;
    }

    .tab-btn-active {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #494949;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }

    .tab-btn-active::before {
        content: '';
        position: absolute;
        width: 30px;
        margin-top: 35px;
        height: 2px;
        border-radius: 3px;
        border-bottom: 2px solid #e54d42;
        box-sizing: border-box;
    }

    .comment-box {
        float: left;
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f6f6;
        background-color: #FFFFFF;
    }

    .comment-box .avatar-box {
        width: 32px;
        height: 32px;
        float: left;
    }
    .comment-box .avatar-box img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
    }

    .comment-box .sender-name {
        float: left;
        width: calc(100% - 32px);
        height: 16px;
        line-height: 16px;
        color: #8799a3;
        font-size: 14px;
        padding-left: 5px;
        box-sizing: border-box;
    }
    .comment-box .sender-time {
        float: left;
        width: calc(100% - 32px);
        height: 16px;
        line-height: 16px;
        color: #8E8E93;
        font-size: 12px;
        padding-left: 5px;
        box-sizing: border-box;
    }

    .comment-box .comment-content {
        padding: 10px 0;
        line-height: 23px;
        font-size: 15px;
        color: #494949;
    }
    .comment-box .comment-ref {
        box-sizing: border-box;
        padding: 10px;
        line-height: 23px;
        font-size: 13px;
        color: gray;
        border-radius: 3px;
        background-color: #F8F8F8;
    }
    .comment-box .comment-ref-2 {
        box-sizing: border-box;
        padding: 10px;
        line-height: 23px;
        font-size: 13px;
        color: gray;
        border-radius: 3px;
        background-color: #ffffff;
    }
</style>
